import React from "react"
import Layout from "../components/layout"
import {HelmetDatoCms} from "gatsby-source-datocms"

export default class Contact extends React.Component {
    render() {
        return (
            <Layout className="solid">
                <HelmetDatoCms>
                    <title>Falve - Kontakt</title>
                    <meta name="description" content="Ul. Żurawia 24A/24, Warszawa 00-515"/>
                </HelmetDatoCms>
                <div className="contact-wrapper">
                    <div className="container">
                        <div className="item">
                            <h3>Kontakt</h3>
                            <div className="row">
                                <span className="icon-home icon"></span>
                                <p>Falve<br/>
                                    Ul. Żurawia 24A/24<br/>
                                    Warszawa 00-515</p>
                            </div>
                            <div className="row">
                                <span className="icon-phone icon"></span>
                                <p>Telefon<br/>
                                    tel. <a href="tel:507266234">507-266-234</a></p>
                            </div>
                            <div className="row">
                                <span className="icon-email icon"></span>
                                <p>Email<br/>
                                    <a href="mailto:kontakt@falve.pl">kontakt@falve.pl</a></p>
                            </div>
                        </div>
                        <div className="item">
                            <h3>Naprawa pomp</h3>
                            <div className="row">
                                <span className="icon-maintenance icon"></span>
                                <p>Seriws<br/>
                                    Ul. Kontruktorska 6<br/>
                                    Warszawa 02-673</p>
                            </div>
                            <div className="row">
                                <span className="icon-phone icon"></span>
                                <p>Telefon<br/>
                                    tel. <a href="tel:507266234">507-266-234</a></p>
                            </div>
                            <div className="row">
                                <span className="icon-email icon"></span>
                                <p>Email<br/>
                                    <a href="mailto:serwis@falve.pl">serwis@falve.pl</a></p>
                            </div>
                        </div>
                        <div className="item">
                            <h3>Sprzedaż</h3>
                            <div className="row">
                                <span className="icon-customer-service icon"></span>
                                <p>Dział sprzedaży<br/>
                                    Ul. Żurawia 24A/24<br/>
                                    Warszawa 00-515</p>
                            </div>
                            <div className="row">
                                <span className="icon-phone icon"></span>
                                <p>Telefon<br/>
                                    tel. <a href="tel:570059598">570-059-598</a></p>
                            </div>
                            <div className="row">
                                <span className="icon-email icon"></span>
                                <p>Email<br/>
                                    <a href="mailto:lodz@falve.pl">lodz@falve.pl</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
